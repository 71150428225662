import React from "react";
import { Link } from "gatsby";
import { FaWhatsapp } from "react-icons/fa";

import { useMisc } from "../hooks/useMisc";

const Header = ({ didScrolled, isMobile, whiteHeader = false }) => {
  const {
    logos,
    footer: { phone },
  } = useMisc();
  const { horizontalLogo, verticalLogo } = logos;
  const logoHorizontal = horizontalLogo.childImageSharp.fluid.src;
  const logoVertical = verticalLogo.childImageSharp.fluid.src;
  return (
    <header>
      <div className="container">
        <div className="logo">
          <Link to="/">
            <img
              src={didScrolled || isMobile ? logoHorizontal : logoVertical}
              alt="Logo"
            />
          </Link>
        </div>
        <nav>
          <Link activeClassName="active" to="/">
            Home
          </Link>
          <Link activeClassName="active" to="/about">
            About Us
          </Link>
          <Link activeClassName="active" to="/shop">
            Shop
          </Link>
          <Link activeClassName="active" to="/frequently-asked-questions">
            FAQ's
          </Link>
          <Link activeClassName="active" to="/blog">
            Blog
          </Link>
          <Link
            activeClassName="active"
            to="/cordyceps-mushroom-production-cultivation-training-india"
          >
            Training
          </Link>
          <a
            className="contact-btn"
            target="_blank"
            rel="noreferrer noopener"
            href={`https://api.whatsapp.com/send?phone=${phone}`}
          >
            <span className="icon">
              <FaWhatsapp size={24} />
            </span>
            <span className="text">{phone}</span>
          </a>
        </nav>
        <nav className="mobile-nav">
          <a
            className="contact-btn"
            target="_blank"
            rel="noreferrer noopener"
            href={`https://api.whatsapp.com/send?phone=${phone}`}
          >
            <span className="icon">
              <FaWhatsapp size={24} />
            </span>
            <span className="text">{phone}</span>
          </a>
        </nav>
      </div>

      <style jsx>{`
        header {
          width: 100%;
          min-height: 55px;
          padding: 8px 32px;
          background: ${whiteHeader ? "#fff" : "#f5f0eb"};
          position: fixed;
          transition: all 0.2s;
          z-index: 100;
          box-shadow: ${didScrolled
            ? "0 1px 3px 0 rgba(0,0,0,.1), 0 1px 2px 0 rgba(0,0,0,.06)"
            : "unset"};
          transition: all 0.2s;
        }

        .container {
          max-width: 1278px;
          margin: 0 auto;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        .logo :global(a) {
          display: block;
        }

        .logo img {
          width: auto;
          height: ${didScrolled || isMobile ? "55px" : "100px"};
          object-fit: contain;
          transition: all 0.2s;
        }

        nav.mobile-nav {
          display: none;
        }

        nav {
          min-height: 55px;
          display: flex;
          align-items: center;
          justify-content: flex-end;
        }

        nav :global(a) {
          font-family: "Lato", sans-serif;
          margin-right: 32px;
          color: #474747;
          font-size: 18px;
          position: relative;
        }

        nav :global(a):hover {
          color: #ff8100;
        }

        nav :global(a):last-child {
          margin-right: 0;
        }

        nav :global(a.active):after {
          background-image: linear-gradient(90deg, #ff5858 13%, #f09819);
          height: 4px;
          content: "";
          position: absolute;
          width: 100%;
          bottom: -10px;
          left: 0;
          border-radius: 2px;
        }

        .contact-btn {
          width: 180px;
          min-height: 40px;
          text-transform: uppercase;
          background-image: linear-gradient(90deg, #ff5858 13%, #f09819);
          padding: 8px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 24px;
          color: #fff;
          font-size: 14px;
          transition: all 0.25s;
        }

        .contact-btn .icon {
          margin-right: 6px;
        }

        .contact-btn .text {
          height: 20px;
        }

        .contact-btn:hover {
          transform: scale(1.05);
          color: #fff;
        }

        nav.mobile-nav .contact-btn {
          width: ${didScrolled ? "45px" : "180px"};
          height: ${didScrolled ? "45px" : "40px"};
          min-height: ${didScrolled ? "45px" : "40px"};
          border-radius: ${didScrolled ? "50%" : "24px"};
          box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
        }

        nav.mobile-nav .contact-btn .icon {
          margin-right: ${didScrolled ? 0 : "6px"};
        }

        nav.mobile-nav .contact-btn .text {
          width: ${didScrolled ? 0 : "auto"};
          opacity: ${didScrolled ? 0 : 1};
        }

        @media (max-width: 980px) {
          nav {
            display: ${didScrolled ? "none" : "flex"};
          }

          nav.mobile-nav {
            display: ${didScrolled ? "flex" : "none"};
          }
        }

        @media (max-width: 900px) {
          header {
            padding: 8px 16px;
          }
          .logo :global(a) {
            margin-left: 0px;
          }
          .contact-btn {
            margin-right: 0;
          }
        }

        @media (max-width: 860px) {
          header {
            padding: 8px 16px;
          }

          nav {
            display: none;
          }

          nav.mobile-nav {
            display: flex;
          }
        }
      `}</style>
    </header>
  );
};

export default Header;
