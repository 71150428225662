import React from "react";
import { Link } from "gatsby";
import cx from "clsx";

const Button = ({
  width = 250,
  isBold = false,
  isSolid = true,
  isInternal = false,
  isSubmitting = false,
  isInverted = false,
  href,
  ...props
}) => {
  return (
    <>
      {href ? (
        isInternal ? (
          <Link
            className={cx(
              "btn-link",
              !isSolid && "btn-link-outline",
              isInverted && "btn-link-inverted"
            )}
            {...props}
            to={href}
          ></Link>
        ) : (
          <a
            className={cx(
              "btn",
              !isSolid && "btn-outline",
              isInverted && "btn-inverted"
            )}
            {...props}
            href={href}
          ></a>
        )
      ) : (
        <button
          className={cx(
            "btn",
            !isSolid && "btn-outline",
            isSubmitting && "btn-loader",
            isInverted && "btn-inverted"
          )}
          {...props}
        ></button>
      )}
      <style jsx>{`
        .btn,
        :global(.btn-link) {
          width: ${typeof width === "number" ? `${width}px` : width};
          min-height: 40px;
          text-transform: uppercase;
          background: linear-gradient(90deg, #ff5858 13%, #f09819);
          padding: 12px 16px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 24px;
          color: #fff;
          font-size: 18px;
          font-family: "Lato", sans-serif;
          transition: all 0.25s;
          cursor: pointer;
          border: none;
        }
        .btn-inverted,
        :global(.btn-link-inverted) {
          background: #fff;
          color: #000;
        }

        :global(.btn-link) {
          display: block;
          text-align: center;
        }

        .btn-outline,
        :global(.btn-link-outline) {
          background: #fff;
          color: #000;
          border: 2px solid #ff8100;
        }

        .btn .icon {
          margin-right: 6px;
        }

        .btn .text {
          height: 28px;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .btn:hover,
        :global(.btn-link):hover {
          transform: scale(1.05);
        }

        .btn:focus {
          outline: none;
        }

        .btn-loader {
          animation: loading 20s linear infinite;
          background-color: #f09819;
          background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABQCAYAAACpv3NFAAADGklEQVR4AWIYSPD//39AsXah4ooORWGYcam767i7+/u/1F3XFxDOrp3+wMcKkZKZhF1dmce/j+GPN+3j0v+AVdgKffprsBX69Ndl7Z/09lpSPN/7Imv09V9P2LD0djw+Pfz0N0Dr+OnLJmiNLn5bsBX09G0j25bLskFf/22zYyne5xY6f5Uufjugbfr0t2QXtE4XvwyJLn4bkpWMZAH49c9IzknW0tuLGnfoc/+a5H6DvKVYn5EMffo7kgdt0sWvAFuhn/qKoF36+uek9I+ipbUT4yacnxg36/Tr/rJvKFC29HY8npbHn/qkDNqmi18FtkI/9VVBWfr6l6TmpGop1rd4G/Tr/toE6pbennd+iT79vNRBO/SHHnVpQOr0pz670gQV6OtfldY/mpZifYFZ19PFb1Na0p5Qy9LaM4/X6NMvSnuJOpZ/yqDFzzZDaNPFLytdDv/c35QeaJMufr2EvqW343EXz2/Sp1+RvgwsxfoC0fpgPIsWP9sIoU8Xv5wMQVX6+ndkBNqiP/Ed22bGlimjqebHuvTp12XPjC1jtsbbU8zPo8XPNkMY08WvIPugOn39+3IA2qZ/53MoB5ZifcF4IFo/wF/3y5EcWor1BaL1wXiRLn5Hcuy5RId08SvJMYd/4zOSE9AO/XXXqW3m1HIZxvTpt+XMnFqm+Bxvx+NpZfqHTidyLmfOnFuK9UXi9Sf0L73Kcg7q0Nf/QC4odPHblUvQAX36PblKuLQU75t/vqnQxe9CruTaMsHmBHxusP6CLn5VuQb16Ot/LDegXbr43YKO6dMfyN0v3Fp6OxyfUJUufjdyZ+4trZ0Yj8Trb+niV0ts+MFSrG/xhvT1P5MHUIb+je8j6Bz/0EOeQHW6+D3K8wSeLBNsjrWD8Ue6+DXkeUYvltaeZj3/qc+lvICy9A+dXkFX9OnvyxuoSRe/V3mfwpultWea/0oXv6a8Bz4sE2zORHwN/6nPjXyAcvQPnT5Bt/TpH8oXqE0Xvw/5nsGXpbfDcfMh6/TXXT//+LZMsDneToxHbO4Rff3v4z8wHp+Wrc2Rf/8fKrJDJf0nqJwAAAAASUVORK5CYII=);
          background-origin: border-box;
          background-position: 0 0;
          background-repeat: repeat-x;
          background-size: contain;
          pointer-events: none;
        }

        @keyframes loading {
          0% {
            background-position: 0 0;
          }
          100% {
            background-position: 700% 0;
          }
        }
      `}</style>
    </>
  );
};

export default Button;
