import React from "react";

import Button from "./Button";
import Underline from "./Underline";
import enquireMail from "../../static/images/enquire_mail.svg";
import { useMisc } from "../hooks/useMisc";

export default ({ isAboutPage = false, isShopPage = false }) => {
  const { enquireNow } = useMisc();
  const { title, description } = enquireNow;
  return (
    <section>
      <div className="container">
        <div className="content">
          <div className="info">
            {isAboutPage ? (
              <h2>Curious to know more about Cordyceps Talk to our experts</h2>
            ) : isShopPage ? (
              <h2>
                Want to buy in bulk or wholesale
                <br /> Talk to our experts
              </h2>
            ) : (
              <h2>{title}</h2>
            )}
            <Underline isInverted />
            <p>{description}</p>
            <div className="btns">
              <Button isInternal href="/contact" isInverted>
                Enquire Now
              </Button>
            </div>
          </div>
          <div className="image-container">
            <img src={enquireMail} alt="mail icon" />
          </div>
        </div>
      </div>
      <div className="waves">
        <svg viewBox="0 0 1428 174">
          <g fill="#FFF" fillRule="nonzero">
            <path
              d="M0 0c90.728.928 147.913 27.188 291.91 59.912C387.908 81.728 543.605 89.335 759 82.732c-289.664 73.522-542.664 70.936-759-7.759"
              opacity={0.100000001}
              transform="translate(-2 44)"
            />
            <path
              d="M100 104.708c177.413-32.473 326.148-52.183 446.204-59.13 120.055-6.945 264.32-3.78 432.796 9.496-47.93 1.049-168.697 19.772-362.3 56.17-193.603 36.397-365.837 34.219-516.7-6.536z"
              opacity={0.100000001}
              transform="translate(-2 44)"
            />
            <path
              d="M1046 51.652c84.83-22.323 233.083-34.044 393-11.486V120c-167.828-42.056-298.828-64.84-393-68.348z"
              opacity={0.200000003}
              transform="translate(-2 44)"
            />
            <g>
              <path
                d="M.457 34.035c56.629 19.163 97.751 31.774 123.365 37.83 57.632 13.63 110.473 18.425 148.211 21.594 39.322 3.3 124.602 2.342 188.992-1.796 25.735-1.653 57.702-5.291 95.901-10.911 38.821-6.156 65.446-10.744 79.873-13.761 27.114-5.667 75.702-17.488 90.806-20.863 52.865-11.811 91.234-23.596 128.719-30.224C922.689 4.169 955.676 2.522 1011.185.432c49.52 1.045 86.205 2.697 110.051 4.955 40.467 3.832 87.385 12.434 114.164 16.917 50.455 8.444 118.951 25.128 205.486 50.05l.305 31.998-1440.07-.321-.664-69.996z"
                transform="translate(-4 76)"
              />
            </g>
          </g>
        </svg>
      </div>
      <style jsx>{`
        section {
          background: linear-gradient(90deg, #ff5858 13%, #f09819);
        }

        .container {
          max-width: 1278px;
          margin: 0 auto;
          padding: 32px 0;
        }

        .content {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-family: "Lato", sans-serif;
          color: #fff;
          position: relative;
          top: 32px;
        }

        .info,
        .image-container {
          order: 1;
        }

        .image-container img {
          width: 100%;
        }

        .info :global(.line) {
          margin: 0;
          margin-bottom: 32px;
        }

        h2 {
          text-align: left;
          font-size: 48px;
          font-weight: 400;
          margin-top: 0;
          font-family: "Open Sans", sans-serif;
          margin-bottom: 16px;
        }

        p {
          font-weight: 300;
          line-height: 1.4;
          font-size: 20px;
          max-width: 600px;
          margin: 0;
          padding: 0;
        }

        .btns {
          margin-top: 16px;
        }

        .waves {
          position: relative;
          top: 4px;
        }

        @media (max-width: 1278px) {
          .container {
            padding: 32px;
          }
        }

        @media (max-width: 1080px) {
          h2 {
            font-size: 32px;
          }
          p {
            max-width: 480px;
          }
        }
        @media (max-width: 980px) {
          .image-container {
            max-width: 300px;
          }
        }
        @media (max-width: 880px) {
          .content {
            align-items: flex-start;
            justify-content: flex-start;
          }
          .info {
            order: 2;
            margin-left: 32px;
          }
          .image-container {
            max-width: 240px;
          }
        }

        @media (max-width: 740px) {
          .content {
            flex-direction: column;
            top: 0;
          }
          .info {
            margin-left: 0px;
            margin-top: 32px;
          }
          .image-container {
            max-width: 150px;
          }
        }

        @media (max-width: 500px) {
          h2 {
            font-size: 28px;
          }
          p {
            font-size: 18px;
          }
        }
      `}</style>
    </section>
  );
};
