import React from "react";
import Flip from "react-reveal/Flip";
import clsx from "clsx";

export default function Divider({ isInverted = false }) {
  return (
    <Flip left>
      <div className={clsx("line", isInverted && "inverted-line")}>
        <style jsx>{`
          .line {
            margin: 0 auto;
            margin-bottom: 48px;
            border-radius: 3px;
            width: 84px;
            height: 4px;
            background-image: linear-gradient(90deg, #ff5858 13%, #f09819);
          }
          .inverted-line {
            background: white;
          }
        `}</style>
      </div>
    </Flip>
  );
}
