import { graphql, useStaticQuery } from "gatsby";

export const useMisc = () => {
  const data = useStaticQuery(graphql`
    query {
      markdownRemark(frontmatter: { templateKey: { eq: "misc" } }) {
        frontmatter {
          coupons {
            item {
              coupon
              discount
              isActive
              hasFlatRate
            }
          }
          logos {
            horizontalLogo {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            verticalLogo {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          footer_images {
            makeInIndia {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            grownInIndia {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          social {
            facebook
            twitter
            instagram
          }
          enquireNow {
            title
            description
          }
          footer {
            title
            companyName
            companyDescription
            email
            phone
            timings
            geo {
              latitude
              longitude
            }
            address {
              state
              city
              locality
              postalCode
              streetName
              country
            }
          }
        }
      }
    }
  `);
  return data.markdownRemark.frontmatter;
};
