import React from "react";
import { Link } from "gatsby";
import {
  FiHome,
  FiUser,
  FiShoppingCart,
  FiBookOpen,
  FiHelpCircle,
  FiMonitor,
} from "react-icons/fi";

export default () => {
  return (
    <nav>
      <Link activeClassName="active" to="/">
        <span className="icon">
          <FiHome size={20} />
        </span>
        <span>Home</span>
      </Link>
      <Link activeClassName="active" to="/about">
        <span className="icon">
          <FiUser size={20} />
        </span>
        <span>About Us</span>
      </Link>
      <Link activeClassName="active" to="/shop">
        <span className="icon">
          <FiShoppingCart size={20} />
        </span>
        <span>Shop</span>
      </Link>
      <Link activeClassName="active" to="/frequently-asked-questions">
        <span className="icon">
          <FiHelpCircle size={20} />
        </span>
        <span>FAQ's</span>
      </Link>
      <Link activeClassName="active" to="/blog">
        <span className="icon">
          <FiBookOpen size={20} />
        </span>
        <span>Blog</span>
      </Link>
      <Link
        activeClassName="active"
        to="/cordyceps-mushroom-production-cultivation-training-india"
      >
        <span className="icon">
          <FiMonitor size={20} />
        </span>
        <span>Training</span>
      </Link>
      <style jsx>{`
        nav {
          position: fixed;
          left: 0;
          bottom: 0;
          width: 100%;
          display: none;
          align-items: center;
          background: #fff;
          z-index: 50;
          font-family: "Lato", sans-serif;
          padding: 0 4px;
          box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
            0 1px 2px 0 rgba(0, 0, 0, 0.06);
        }
        nav :global(a) {
          flex: 1;
          font-size: 12px;
          color: #888;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          min-height: 55px;
        }

        nav :global(a:not(:last-child)) {
          margin-right: 4px;
        }

        nav :global(a.active) {
          color: #fe8100;
        }

        @media (max-width: 860px) {
          nav {
            display: flex;
          }
        }
        @media (max-width: 395px) {
          nav :global(a) {
            font-size: 9px;
          }
        }
      `}</style>
    </nav>
  );
};
