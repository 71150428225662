import React from "react";
import { MdAccessTime, MdLocalPhone, MdPlace } from "react-icons/md";
import { FaWhatsapp } from "react-icons/fa";
import { Link } from "gatsby";
import Img from "gatsby-image";

import facebookIcon from "../img/social/facebook.svg";
import instagramIcon from "../img/social/instagram.svg";
import twitterIcon from "../img/social/twitter.svg";
import Button from "./Button";
import { useMisc } from "../hooks/useMisc";
import PreviewCompatibleImage from "./PreviewCompatibleImage";

const Footer = () => {
  const { footer, social, footer_images: footerImages } = useMisc();
  const {
    address,
    title,
    companyDescription: description,
    phone,
    timings,
  } = footer;
  const { streetName, locality, city, state, postalCode, country } = address;
  const { facebook, twitter, instagram } = social;
  const { makeInIndia, grownInIndia } = footerImages;
  return (
    <footer>
      <div className="footer-content">
        <div className="container">
          <div className="about">
            <h4>{title}</h4>
            <p>{description}</p>
            <p>
              <span className="icon">
                <MdPlace />
              </span>
              {streetName}, {locality}, {city} ({state}) {postalCode} {country}
            </p>
            <p>
              <span className="icon">
                <MdLocalPhone />
              </span>

              <a href={`tel:${phone}`}>{phone}</a>
            </p>
            <p>
              <span className="icon">
                <MdAccessTime />
              </span>
              {timings}
            </p>
          </div>
          <div className="menu">
            <h4>Menu</h4>
            <nav>
              <Link to="/">Home</Link>
              <Link to="/about">About Us</Link>
              <Link to="/shop">Shop</Link>
              <Link to="/frequently-asked-questions">FAQ's</Link>
              <Link to="/blog">Blog</Link>
              <Link to="/contact">Contact Us</Link>
              <Link to="/privacy-policy">Privacy Policy</Link>
              <Link to="/return-policy">Return Policy</Link>
            </nav>
          </div>
          <div className="contact">
            <div>
              <h4>Whatsapp us for more info</h4>
              <Button
                isBold
                href={`https://api.whatsapp.com/send?phone=${phone}`}
              >
                <span className="icon">
                  <FaWhatsapp size={24} />
                </span>
                <span className="text">{phone}</span>
              </Button>
            </div>
            <div className="image-container">
              <Img
                fluid={makeInIndia.childImageSharp.fluid}
                alt={"make in india"}
                className="make-in-india"
              />
              <Img
                fluid={grownInIndia.childImageSharp.fluid}
                alt={"grown in india"}
                className="grown-in-india"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="toc">
        <div className="container">
          <p>All right reserved ©{new Date().getFullYear()} mycoforest.com</p>
          <div className="social">
            <a title="facebook" href={facebook}>
              <img
                src={facebookIcon}
                alt="Facebook"
                style={{ width: "1em", height: "1em" }}
              />
            </a>
            <a title="twitter" href={twitter}>
              <img
                className="fas fa-lg"
                src={twitterIcon}
                alt="Twitter"
                style={{ width: "1em", height: "1em" }}
              />
            </a>
            <a title="instagram" href={instagram}>
              <img
                src={instagramIcon}
                alt="Instagram"
                style={{ width: "1em", height: "1em" }}
              />
            </a>
          </div>
        </div>
      </div>
      <style jsx>{`
        footer {
          width: 100%;
          color: #fff;
        }

        .toc {
          background: #555555;
          padding: 16px 32px;
        }

        .footer-content {
          background: #727272;
          padding: 32px;
        }

        .container {
          max-width: 1278px;
          margin: 0 auto;
          display: flex;
          justify-content: space-between;
        }

        .toc .container {
          background: #555555;
          align-items: center;
        }

        .about {
          max-width: 540px;
        }

        h4 {
          font-size: 20px;
          font-family: "Open Sans", sans-serif;
          font-weight: 700;
          margin-bottom: 32px;
        }

        p {
          font-size: 16px;
          display: flex;
          align-items: center;
          font-family: "Open Sans", sans-serif;
          font-weight: 400;
        }

        .about p {
          margin-bottom: 12px;
        }

        .toc p {
          font-size: 14px;
        }

        p .icon {
          margin-right: 6px;
        }

        p a {
          color: #fff;
          border-bottom: 1px dotted #aaa;
          transition: all 0.2s;
        }

        p a:hover {
          border-bottom-color: #ddd;
        }

        .menu {
          text-align: center;
          min-width: 100px;
        }
        nav {
          display: flex;
          flex-direction: column;
          align-items: center;
          min-width: 100px;
        }

        nav :global(a) {
          display: block;
          margin-bottom: 8px;
          font-size: 16px;
          font-family: "Open Sans", sans-serif;
          font-weight: 400;
          color: #fff;
          transition: all 0.2s;
        }

        nav :global(a):hover {
          font-weight: 600;
        }

        .contact {
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
        }

        .icon {
          margin-right: 6px;
          position: relative;
          top: 2px;
        }

        .text {
          height: 24px;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .toc .social {
          padding: 0;
        }

        .social a {
          padding: 0.5em 0.5em 0.3em 0.5em;
          border-radius: 1em;
          background-color: #eee;
          margin: 0.5em;
          vertical-align: middle;
          display: inline;
          transition: all 0.25s;
        }

        .social a:hover {
          background-color: #fff;
        }

        .image-container {
          display: flex;
          margin-top: 32px;
        }

        .image-container :global(.gatsby-image-wrapper) {
          width: 200px;
          height: 164;
        }

        .image-container :global(.gatsby-image-wrapper.grown-in-india) {
          width: 130px;
        }

        .image-container :global(.gatsby-image-wrapper) :global(img) {
          object-fit: contain !important;
        }

        @media (max-width: 1040px) {
          .contact {
            display: none;
          }
        }

        @media (max-width: 860px) {
          footer {
            padding-bottom: 55px;
          }
        }

        @media (max-width: 780px) {
          .container {
            flex-direction: column;
          }

          .about,
          .menu,
          .contact {
            margin-bottom: 24px;
          }

          h4 {
            margin-bottom: 16px;
            font-size: 18px;
          }

          .menu {
            text-align: left;
            min-width: unset;
            margin-bottom: 32px;
          }
          nav {
            flex-direction: row;
          }

          nav :global(a) {
            margin-bottom: 0;
            margin-right: 12px;
          }

          .contact {
            display: flex;
            align-items: center;
            flex-direction: row;
            justify-content: space-between;
          }

          .contact h4 {
            margin-bottom: 20px;
          }

          .toc .container {
            align-items: center;
          }

          .toc p {
            margin-bottom: 16px;
          }

          .image-container {
            width: 100%;
            justify-content: space-around;
          }

          .image-container :global(.gatsby-image-wrapper) {
            width: 200px;
            height: 164;
          }

          .image-container :global(.gatsby-image-wrapper.grown-in-india) {
            width: 130px;
          }
        }

        @media (max-width: 600px) {
          .footer-content,
          .toc {
            padding: 24px;
          }

          .image-container {
            max-width: 400px;
            margin: 16px auto;
            justify-content: space-between;
          }

          .contact {
            flex-direction: column;
          }
        }

        @media (max-width: 480px) {
          .menu {
            margin-bottom: 16px;
          }

          nav {
            flex-direction: column;
            align-items: flex-start;
          }

          nav :global(a) {
            margin-bottom: 12px;
            margin-right: 0;
          }

          .image-container :global(.gatsby-image-wrapper) {
            width: 150px;
            height: 130;
          }

          .image-container :global(.gatsby-image-wrapper.grown-in-india) {
            width: 100px;
          }
        }
      `}</style>
    </footer>
  );
};

export default Footer;
