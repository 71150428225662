import React, { Component } from "react";
import { navigate } from "gatsby-link";

import Button from "./Button";
import Underline from "./Underline";
import { encode } from "../utils";

export default class SubscribeForm extends Component {
  constructor(props) {
    super(props);
    this.state = { isValidated: false, isSubmitting: false };
  }
  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = e => {
    e.preventDefault();
    const form = e.target;
    this.setState({ isSubmitting: true });
    fetch("/", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...this.state
      })
    })
      .then(() => {
        this.setState({ isSubmitting: false });
        navigate(form.getAttribute("action"));
      })
      .catch(error => {
        this.setState({ isSubmitting: false });
        alert(error);
      });
  };
  render() {
    return (
      <section>
        <div className="container">
          <h2>Join the l∞p</h2>
          <Underline />
          <p>
            Get alerts of our latest blogs and exciting offers on our products.
          </p>
          <form
            name="subscribe"
            method="post"
            action="/contact/thanks/"
            data-netlify="true"
            data-netlify-honeypot="bot-subscribe-field"
            onSubmit={this.handleSubmit}
          >
            {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
            <input type="hidden" name="form-name" value="subscribe" />
            <div hidden>
              <label>
                Don’t fill this out:{" "}
                <input
                  name="bot-subscribe-field"
                  onChange={this.handleChange}
                  disabled={this.state.isSubmitting}
                />
              </label>
            </div>
            <div className="control">
              <input
                className="input"
                type={"email"}
                name={"email"}
                onChange={this.handleChange}
                id={"s-email"}
                required={true}
                placeholder="Enter your email to subscribe"
                disabled={this.state.isSubmitting}
              />
              <div className="submit-container">
                <Button width={224} type="submit"  disabled={this.state.isSubmitting}  isSubmitting={this.state.isSubmitting}>
                  Subscribe
                </Button>
              </div>
            </div>
          </form>
        </div>
        <style jsx>{`
          section {
            background: #fff;
            padding: 96px 32px;
            padding-bottom: 197px;
          }

          .container {
            max-width: 1278px;
            margin: 0 auto;
          }

          h2 {
            font-size: 48px;
            text-align: center;
            margin: 0;
            margin-bottom: 12px;
            font-family: "Open Sans", sans-serif;
            font-weight: inherit;
          }

          p {
            font-family: "Lato", sans-serif;
            font-weight: 300;
            margin-bottom: 16px;
            padding: 0 16px;
            text-align: center;
            font-size: 20px;
          }

          form {
            max-width: 900px;
            width: 100%;
            margin: 0 auto;
          }

          .control {
            display: flex;
            width: 100%;
            border: 1px solid #e2e8f0;
            border-radius: 999px;
            padding: 3px;
          }

          input {
            width: 100%;
            padding: 12px 16px;
            display: block;
            border: none;
            border-radius: 999px;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            outline: none;
            font-size: 18px;
            background: transparent;
          }
          ::-webkit-input-placeholder {
            color: #999;
            font-family: "Lato", sans-serif;
            font-weight: 300;
          }
          ::-moz-placeholder {
            color: #999;
            font-family: "Lato", sans-serif;
            font-weight: 300;
          }
          :-ms-input-placeholder {
            color: #999;
            font-family: "Lato", sans-serif;
            font-weight: 300;
          }
          :-moz-placeholder {
            color: #999;
            font-family: "Lato", sans-serif;
            font-weight: 300;
          }

          @media (max-width: 640px) {
            .control {
              flex-direction: column;
              border: none;
              border-radius: 0;
            }
            input {
              border: 1px solid #e2e8f0;
              border-radius: 999px;
              margin-bottom: 16px;
            }
            .submit-container {
              display: flex;
              justify-content: center;
            }
          }

          @media (max-width: 480px) {
            section {
              padding: 64px 20px;
            }
          }
        `}</style>
      </section>
    );
  }
}
