import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { withPrefix } from "gatsby";
import {
  GatsbySeo,
  LogoJsonLd,
  LocalBusinessJsonLd
} from "gatsby-plugin-next-seo";

import Footer from "./Footer";
import Navbar from "./Navbar";
import Mobileavbar from "./MobileNav";
import SubscribeForm from "./SubscribeForm";
import EnquireNow from "./Enquire";
import useSiteMetadata from "./SiteMetadata";

import "./all.sass";
import { useMisc } from "../hooks/useMisc";

const TemplateWrapper = ({
  children,
  whiteHeader = false,
  isContactPage = false,
  isAboutPage = false,
  isShopPage = false
}) => {
  const { title, description, siteUrl } = useSiteMetadata();
  const {
    footer,
    logos: { horizontalLogo }
  } = useMisc();
  const [didScrolled, setDidScrolled] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const logoHorizontal = horizontalLogo.childImageSharp.fluid.src;
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (
        document.body.scrollTop > 50 ||
        document.documentElement.scrollTop > 50
      ) {
        setDidScrolled(true);
      } else {
        setDidScrolled(false);
      }
    });

    window.matchMedia("(max-width: 800px)").addListener(e => {
      if (e.matches) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    });
  }, []);

  const ogImageUrl = `${siteUrl}/images/immune_enhancer_3.jpg`;
  return (
    <div>
      <GatsbySeo
        title={title}
        description={description}
        openGraph={{
          url: siteUrl,
          title,
          description,
          images: [{ url: ogImageUrl }],
          site_name: "MycoForest"
        }}
        twitter={{
          handle: "@mycoforest",
          site: "@mycoforest",
          cardType: "summary_large_image"
        }}
      />
      <LocalBusinessJsonLd
        type="Store"
        id={siteUrl}
        name={footer.companyName}
        description={footer.companyDescription}
        url={siteUrl}
        telephone={footer.phone}
        priceRange="₹3000 - ₹5000"
        address={{
          streetAddress: footer.address.streetName,
          addressLocality: footer.address.locality,
          addressRegion: footer.address.city,
          postalCode: footer.address.postalCode,
          addressCountry: footer.address.country
        }}
        geo={footer.geo}
        images={[ogImageUrl]}
      />
      <LogoJsonLd logo={`${siteUrl}${logoHorizontal}`} url={siteUrl} />
      <Helmet>
        <html lang="en" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`${withPrefix("/")}images/apple-touch-icon.png`}
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix("/")}images/favicon-32x32.png`}
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix("/")}images/favicon-16x16.png`}
          sizes="16x16"
        />

        <link
          rel="mask-icon"
          href={`${withPrefix("/")}images/safari-pinned-tab.svg`}
          color="#ff4400"
        />
        <meta name="theme-color" content="#ff8100" />
      </Helmet>
      <Navbar
        whiteHeader={whiteHeader}
        didScrolled={didScrolled}
        isMobile={isMobile}
      />
      <main>{children}</main>
      {!isContactPage && (
        <EnquireNow isAboutPage={isAboutPage} isShopPage={isShopPage} />
      )}
      <SubscribeForm />
      <Footer />
      <Mobileavbar />
      <style jsx>{`
        main {
          padding-top: ${didScrolled || isMobile ? 55 : 120}px;
        }
      `}</style>
    </div>
  );
};

export default TemplateWrapper;
