import { format } from "date-fns";

export function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

export function getProductPrice(price, discount) {
  return price - (price * (discount / 100)).toFixed(0);
}

export function getBatchsWithDate(product) {
  return product.batches.map((batch) => {
    const startDate = new Date(batch.start_date);
    const endDate = new Date(batch.end_date);
    const date = `${format(startDate, "dd MMM yyyy")} to ${format(endDate, "dd MMM yyyy")}`;
    return {
      ...batch,
      date,
    };
  });
}
