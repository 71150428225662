import React from "react";
import PropTypes from "prop-types";
import Img from "gatsby-image";
import clsx from "clsx";

const PreviewCompatibleImage = ({ imageInfo, isZoomable, alt = "" }) => {
  const imageStyle = { borderRadius: "5px" };
  const { alt: imgAlt = alt, childImageSharp, image } = imageInfo;

  if (!!image && !!image.childImageSharp) {
    return (
      <Img
        style={imageStyle}
        fluid={image.childImageSharp.fluid}
        alt={imgAlt}
        className={clsx(isZoomable && "is-zoomable")}
      />
    );
  }

  if (!!childImageSharp) {
    return (
      <Img
        style={imageStyle}
        fluid={childImageSharp.fluid}
        alt={imgAlt}
        className={clsx(isZoomable && "is-zoomable")}
      />
    );
  }

  if (!!image && typeof image === "string")
    return (
      <img
        style={imageStyle}
        src={image}
        alt={imgAlt}
        className={clsx(isZoomable && "is-zoomable")}
      />
    );

  return null;
};

PreviewCompatibleImage.propTypes = {
  imageInfo: PropTypes.shape({
    alt: PropTypes.string,
    childImageSharp: PropTypes.object,
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    style: PropTypes.object
  }).isRequired
};

export default PreviewCompatibleImage;
